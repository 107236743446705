import React, { useState, useEffect, useRef } from "react";
import SVG from "../../../Utils/SVG";
import logoValleSabbiaNewsCasa from "./../../../../img/logo_casa.jpg";

const CACHE_DURATION = 60 * 60 * 1000 * 0.5; // Durata della cache in millisecondi (0.5 ore, 30 min)

const ValleSabbiaNewsCasa = () => {
    const intervalRef = useRef(null);
    const [lavoroData, setLavoroData] = useState(null);
    const [currentJobIndex, setCurrentJobIndex] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            // Controlla prima nel localStorage
            const cache = localStorage.getItem("estateDataCache");
            const now = new Date().getTime();

            let data;
            if (cache) {
                const { lastFetch, data: cachedData } = JSON.parse(cache);
                if (now - lastFetch < CACHE_DURATION && cachedData.length > 0) {
                    data = cachedData; // Usa i dati della cache se sono ancora validi
                }
            }

            if (!data) {
                try {
                    const response = await fetch("https://casa.vallesabbianews.it/share_api.php?function=lastEstate");
                    const jsonData = await response.json();
                    data = jsonData.data.estates;

                    // Salva i dati nel localStorage con timestamp
                    localStorage.setItem("estateDataCache", JSON.stringify({ data, lastFetch: now }));
                } catch (error) {
                    console.error("Errore nella richiesta dei dati:", error);
                    // Usa i dati della cache se disponibili e validi
                    if (cache) {
                        data = JSON.parse(cache).data;
                    }
                }
            }

            setLavoroData(data);
        };

        fetchData();
    }, []);

    useEffect(() => {
        const handleInterval = () => {
            setCurrentJobIndex((prev) => (prev + 1) % (lavoroData ? lavoroData.length : 1));
        };

        intervalRef.current = setInterval(handleInterval, 5000);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [lavoroData]);

    const handleMouseEnter = () => {
        clearInterval(intervalRef.current);
    };

    const handleMouseLeave = () => {
        intervalRef.current = setInterval(() => {
            setCurrentJobIndex((prev) => (prev + 1) % (lavoroData ? lavoroData.length : 1));
        }, 5000);
    };

    const convertCSSStringToObject = (cssString) => {
        return cssString
            .split(";")
            .filter((style) => style.trim() !== "")
            .map((style) => style.split(":"))
            .reduce((styles, keyValue) => {
                const key = keyValue[0].trim().replace(/-([a-z])/g, (match) => match[1].toUpperCase());
                styles[key] = keyValue[1].trim();
                return styles;
            }, {});
    };

    return (
        <section className="vsnCasaLavoro">
            <a href="https://casa.vallesabbianews.it/" target="_blank">
                <img className="img-fluid w-100" src={logoValleSabbiaNewsCasa} alt="Logo ValleSabbiaNews Casa" />
            </a>
            <hr />

            {lavoroData &&
                lavoroData.map((estate, index) => (
                    <div
                        key={index}
                        className={"grid"}
                        style={{ display: index === currentJobIndex ? "grid" : "none" }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <h6 className="h5" dangerouslySetInnerHTML={{ __html: estate.name }} />
                        <div className="position-relative">
                            <label
                                className="vendita font-sans text-uppercase font-small px-2 py-1 position-absolute"
                                style={convertCSSStringToObject(estate.offer_style)}
                            >
                                {estate.offer_name}
                            </label>
                            <a
                                href="https://casa.vallesabbianews.it/"
                                target="_blank"
                                className="d-block"
                                style={{ "--aspect-ratio": "1/1" }}
                                aria-label="Maggiori informazioni sull'annuncio"
                            >
                                <img src={estate.image} />
                            </a>
                        </div>
                        <div className="ps-2 pt-1">
                            <ul className="font-sans">
                                {estate.paese && (
                                    <li>
                                        {estate.paese_label}:{" "}
                                        <strong dangerouslySetInnerHTML={{ __html: estate.paese }} />
                                    </li>
                                )}
                                {estate.tipo && (
                                    <li className="mt-2">
                                        {estate.tipo_label}:{" "}
                                        <strong dangerouslySetInnerHTML={{ __html: estate.tipo }} />
                                    </li>
                                )}
                                {estate.superficie && (
                                    <li className="mt-2">
                                        {estate.superficie_label}{" "}
                                        <strong dangerouslySetInnerHTML={{ __html: estate.superficie }} />
                                    </li>
                                )}
                                {estate.superficie_com && (
                                    <li className="mt-2">
                                        {estate.superficie_com_label}{" "}
                                        <strong dangerouslySetInnerHTML={{ __html: estate.superficie_com }} />
                                    </li>
                                )}
                                {estate.prezzo && (
                                    <li className="mt-2">
                                        {estate.prezzo_label}:{" "}
                                        <strong dangerouslySetInnerHTML={{ __html: estate.prezzo }} />
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}

            <a
                href="https://casa.vallesabbianews.it/"
                target="_blank"
                title="Scopri tutti gli annunci immobiliari"
                className="btn btn-primary d-flex justify-content-between mt-3"
            >
                Scopri tutti gli annunci
                <div className="svgWrapperAlt" dangerouslySetInnerHTML={{ __html: SVG.rightArrowWhite }} />
            </a>
        </section>
    );
};

export default ValleSabbiaNewsCasa;
