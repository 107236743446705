import React, { useState } from "react";
//import styles from './RecoverPasswordModal.module.scss';
import Form from "../../Form/Index";

const RecoverPasswordModal = () => {
	return (
		<div
			className="modal fade"
			id="recoverPasswordModal"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="recoverPasswordModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					{/** Header */}
					<div className="modal-header">
						<div>
							<h1 className="modal-title fs-5" id="recoverPasswordModalLabel">
								Recupera la tua password
							</h1>
							<p className="font-sans mt-2">
								Inserisci l’indirizzo email con cui ti sei registrato a ValleSabbiaNews.it, riceverai
								un’email con le indicazioni per procedere al recupero della tua password.
							</p>
						</div>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Chiudi"
						></button>
					</div>

					{/** Body */}
					<div className="modal-body">
						<Form
							apiUrl={"/api/auth/forgot-password"}
							refreshDelay={0}
							stateReset={false}
							fieldsHTML={
								<>
									<div className="form-group">
										<label htmlFor="username" className="mb-1">
											E-Mail
										</label>
										<input
											type="text"
											className="form-control"
											name="email"
											placeholder="Inserisci qui la tua mail con cui ti sei registrato"
											inputMode="email"
										/>
									</div>

									<input type="submit" className="btn btn-primary mt-4" value="Invia E-Mail" />
								</>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RecoverPasswordModal;
