import React, { useEffect, useState } from "react";
import styles from "./NewsArticle.module.scss";
import { usePage } from "@inertiajs/react";
import ShortcodeGallery from "../NewsShortcodes/Gallery/Index";
import SVG from "../../../Utils/SVG";
import SubscriptionPaywall from "../../Subscription/SubscriptionPaywall/Index";
import SmartImage from "../../Images/SmartImage/Index";
import reactStringReplace from "react-string-replace";
import Swiper, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipeDynamicCaption from "photoswipe-dynamic-caption-plugin";
import "photoswipe/dist/photoswipe.css";
import "photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css";
import ImageUtils from "../../../Utils/ImageUtils";
import DateUtils from "../../../Utils/DateUtils";
import ShareList from "../../Share/ShareList/Index";
import AttachmentLinkList from "../../Attachments/AttachmentLinkList/Index";
import FileUtils from "../../../Utils/FileUtils";
import PDFViewer from "../../PDFViewer/Index";
import AudioPlayer from "../../AudioPlayer/Index";
import ShortcodeMedia from "../NewsShortcodes/Media/Index";
import ShareUtils from "../../../Utils/ShareUtils";
import CommentSection from "../../Comment/CommentSection/Index";

function NewsArticle() {
    const { url } = usePage();
    const { locale, magazineNews, userLogged, ziggy } = usePage().props;

    const subscriptionPackagesEnabled = magazineNews.pa_package_enabled; // { 1: "1", 2: "0", 3: "0", 4: "0", 5: "0" }

    // Verifica se userLogged.private_package_active esiste e ha un id
    const userActivePackageId = userLogged?.private_package_active?.private_package_id || null;

    // Controllo se l'articolo è in preview o meno
    const urlObj = new URL(url, ziggy.url);
    const previewParam = urlObj.searchParams.get("preview") || null;
    const isArticlePreview = Boolean(previewParam);

    // Se userLogged.private_package_active è null oppure il pacchetto dell'utente non è abilitato, l'articolo è dietro paywall
    // L'articolo non è dietro paywall se il pacchetto per l'ID 1 è abilitato (impostato su "1") -> Contenuto libero
    const paywalled = !(
        subscriptionPackagesEnabled["1"] === "1" ||
        (userActivePackageId && subscriptionPackagesEnabled[userActivePackageId] === "1") ||
        isArticlePreview === true
    );

    // Medias
    const magazineNewsMedias = magazineNews.magazine_news_medias;
    const hasMagazineNewsMedias = magazineNews.magazine_news_medias.length > 0;
    const hasDownloadableMagazineNewsMedias = magazineNews.magazine_news_medias.some(
        (attachment) => attachment.filename
    );
    // Trovo se c'è un elemento con `is_cover_media` uguale a 1
    const coverMedia = magazineNewsMedias.find((media) => media.is_cover_media === 1);
    // Se `coverMedia` esiste, allora imposto `hasMediaAsCover` a true, altrimenti sarà false
    const hasMediaAsCover = Boolean(coverMedia);

    // Se è una video news
    const isVideoNews = magazineNews.custom_fields?.video_news == "1";

    // Se è una audio news
    const isAudioNews = magazineNews.custom_fields?.audio_news == "1";

    const ArticleFooter = () => {
        return (
            <>
                {/* Elenco allegati vari */}
                {hasMagazineNewsMedias && hasDownloadableMagazineNewsMedias && (
                    <AttachmentLinkList attachments={magazineNews.magazine_news_medias} />
                )}

                {/* Footer */}
                <footer className="mt-4">
                    {/** Data e Copyright */}
                    {/*<span className={`${styles.date} text-uppercase font-small font-sans d-block`}>
						{DateUtils.convertDateFromString(magazineNews.publish_datetime)}
					</span>*/}
                    <span className={`${styles.reserved} text-uppercase font-small font-sans d-block`}>
                        &copy; Riproduzione riservata
                    </span>

                    {/** Elenco tag */}
                    {magazineNews.magazine_tag.length > 0 && (
                        <ul className={`font-sans mt-3 ${styles.tagList}`}>
                            <li>Parole chiave e tag:</li>
                            {magazineNews.magazine_tag.map((tag, index) => {
                                // Primo, verifica se tag.title ha un valore valido
                                const title = tag.title[locale];
                                if (title && title.trim() !== "") {
                                    return <li key={`magazineNewsTag-${index}`}>{title}</li>;
                                }

                                // Se tag.title non ha un valore valido, verifica tag.name
                                if (tag.name && tag.name.trim() !== "") {
                                    return <li key={`magazineNewsTag-${index}`}>{tag.name}</li>;
                                }

                                // Se nessuno dei due ha un valore valido, non restituire nulla
                                return null;
                            })}
                        </ul>
                    )}

                    {/* Commenti */}
                    {/*<button className={`text-uppercase text-primary fw-bold font-sans d-flex align-items-center bg-white border-0 mt-4 ${styles.btnCommentNow}`}>
                        <div
                            className={`me-2`}
                            dangerouslySetInnerHTML={{
                                __html: SVG.commentsPrimary,
                            }}
                        />
                        Commenta ora l'articolo
                    </button>*/}
                    {magazineNews.is_commentable > 0 && (
                        <>
                            <hr className="separatore" />
                            <CommentSection comments={magazineNews.magazine_comment} />
                        </>
                    )}
                </footer>
            </>
        );
    };

    /*
    Questa era funzionante prima dell'attivazione dell'SSR, poi non era più possibile accedere a DOMParser dalla window
    function processContent(content) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        
        // Gestione aspect-ratio iframe video youtube.com/vimeo.com
        const divs = doc.querySelectorAll('div.raw-html-embed iframe[src*="youtube.com"], div.raw-html-embed iframe[src*="vimeo.com"]');
        
        divs.forEach(iframe => {
            const parentDiv = iframe.parentElement;
            if (parentDiv) {
                parentDiv.style.setProperty('--aspect-ratio', '16 / 9');
                parentDiv.classList.add('mt-3');
            }
        });
        
        return doc.body.innerHTML;
    }*/

    function processContent(content) {
        return content?.replace(
            /<div class="raw-html-embed">.*?<iframe.*?src="(?:https?:\/\/)?(?:www\.)?(youtube\.com|vimeo\.com)\/.*?<\/iframe>.*?<\/div>/gs,
            (match) => {
                return match.replace(
                    /<div class="raw-html-embed">/g,
                    '<div class="raw-html-embed mt-3" style="--aspect-ratio: 16 / 9;">'
                );
            }
        );
    }

    const ArticleContents = () => {
        const { locale, magazineNews } = usePage().props;
        const shortCodes = magazineNews.shortCodes[locale];
        const content = processContent(magazineNews.content[locale]);

        const marginClass =
            !isAudioNews &&
            !isVideoNews &&
            !hasMediaAsCover &&
            (!magazineNews.cover_image || magazineNews.cover_image.length == 0)
                ? "mt-3"
                : "";

        // Controlla se shortCodes è un oggetto vuoto
        // Se è vuoto restituisci immediatamente l'articolo intero
        if (Object.keys(shortCodes).length === 0) {
            return (
                <div className={`${styles.articleContents} ${marginClass}`}>
                    {/* Contenuto */}
                    <div dangerouslySetInnerHTML={{ __html: content }} />

                    {/* Cover Image a fine articolo se media presente all'inizio */}
                    {!paywalled &&
                        (isAudioNews || isVideoNews || hasMediaAsCover) &&
                        magazineNews.cover_image &&
                        magazineNews.cover_image.length > 0 && (
                            <CoverImageSwiper coverImages={magazineNews.cover_image} />
                        )}

                    {/* Footer articolo */}
                    {ArticleFooter()}
                </div>
            );
        }

        // Se ci sono shortcodes, cicla l'articolo e sostituisci gli shortcodes con gli effettivi componenti
        let replacedContent = content,
            shortcodeIndex = 0;

        Object.keys(shortCodes).forEach((code) => {
            const bracketedCode = `[${code}]`; // Aggiunta di parentesi quadre attorno al codice
            replacedContent = reactStringReplace(replacedContent, bracketedCode, (match, index) => {
                // Check se il codice include "img" o "media" e restituisce il componente appropriato
                if (code.includes("img")) {
                    return (
                        <ShortcodeGallery
                            key={`ShortcodeGallery-${shortcodeIndex}`}
                            data={shortCodes[code]}
                            galleryID={`ShortcodeGallery-${shortcodeIndex}`}
                        />
                    );
                } else if (code.includes("media")) {
                    return <ShortcodeMedia key={`ShortcodeMedia-${index}`} data={shortCodes[code]} />;
                } else {
                    // Se il codice non contiene "img" o "media", restituisce un errore di visualizzazione
                    return <span>Impossibile visualizzare il contenuto multimediale</span>;
                }
            });
            shortcodeIndex++;
        });

        return (
            <div className={`${styles.articleContents} ${marginClass}`}>
                {/* Contenuto */}
                {replacedContent.map((chunk, index) =>
                    typeof chunk === "string" ? (
                        <div dangerouslySetInnerHTML={{ __html: chunk }} key={`articleContents-${index}`} />
                    ) : (
                        chunk
                    )
                )}
                {/* Cover Image a fine articolo se media presente all'inizio */}
                {!paywalled &&
                    (isAudioNews || isVideoNews || hasMediaAsCover) &&
                    magazineNews.cover_image &&
                    magazineNews.cover_image.length > 0 && <CoverImageSwiper coverImages={magazineNews.cover_image} />}

                {/* Footer articolo */}
                {ArticleFooter()}
            </div>
        );
    };

    const publicationDate = DateUtils.convertDateFromString(magazineNews.publish_datetime);
    const publicationTime = DateUtils.formatTimeToHHMM(magazineNews.publish_datetime);

    let displayDate = publicationDate;
    let displayTime = `Aggiornato alle ${publicationTime}`;

    // Controlla se 'updated_at' è più recente della data di pubblicazione
    if (magazineNews.updated_at) {
        const updatedDate = DateUtils.convertDateFromString(magazineNews.updated_at);
        const updatedTime = DateUtils.formatTimeToHHMM(magazineNews.updated_at);
        const publicationDateTime = new Date(publicationDate + "T" + publicationTime);
        const updatedDateTime = new Date(updatedDate + "T" + updatedTime);

        // Verifica che la data/ora di aggiornamento sia più recente della data/ora di pubblicazione
        if (updatedDateTime > publicationDateTime) {
            if (updatedDate !== publicationDate) {
                displayTime = `Aggiornato il ${updatedDate} alle ${updatedTime}`;
            } else if (updatedTime !== publicationTime) {
                displayTime = `Aggiornato alle ${updatedTime}`;
            }
        }
    }

    let shareLinks = [...ShareUtils.shareLinks];

    // Se non c'è il paywall, mostro il tasto per la stampa
    if (!paywalled) {
        shareLinks.push({
            icon: SVG.printIconWhite,
            href: `https://www.vallesabbianews.it/api/magazine_news/${magazineNews.id}/pdf`,
            title: "Stampa questo articolo",
        });
    }

    const CoverImageSwiper = ({ coverImages }) => {
        const [swiper, setSwiper] = useState(null);

        useEffect(() => {
            if (!swiper && coverImages && coverImages.length > 0) {
                const swiperInstance = new Swiper(".swiper.swiperNewsHeader", {
                    modules: [Navigation],
                    slidesPerView: 1,
                    navigation: {
                        nextEl: ".swiper.swiperNewsHeader .swiper-button-next",
                        prevEl: ".swiper.swiperNewsHeader .swiper-button-prev",
                    },
                });
                setSwiper(swiperInstance);

                async function prepareLightbox() {
                    let imagesList = Array.from(document.querySelectorAll(".swiper.swiperNewsHeader a"));
                    await ImageUtils.prepareImagesForPhotoswipe(imagesList);
                }
                prepareLightbox();

                let lightbox = new PhotoSwipeLightbox({
                    gallery: ".swiper.swiperNewsHeader",
                    children: "a",
                    pswpModule: () => import("photoswipe"),
                });
                new PhotoSwipeDynamicCaption(lightbox, { type: "below" });
                lightbox.init();
            } else if (swiper && coverImages.length > 0) {
                swiper.update();
            }
        }, [swiper, coverImages]);

        return (
            <div className="swiper swiperNewsHeader mb-2">
                <div className="swiper-wrapper">
                    {coverImages.map((image, index) => {
                        const imageTitle = image.data?.title?.[0] ?? "";
                        const imageAlt = image.data?.alt?.[0] ?? "";
                        const imageCopyright = image.data?.copyright?.[0]?.value ?? "";

                        return (
                            <div
                                className="swiper-slide"
                                key={`swiperNewsHeader-${index}`}
                                itemProp="associatedMedia"
                                itemScope
                                itemType="http://schema.org/ImageObject"
                            >
                                <a
                                    style={{
                                        "--aspect-ratio": "16/9",
                                    }}
                                    className={`my-3 d-block ${styles.mainImageWrapper}`}
                                    itemProp="contentUrl"
                                    href={image.url}
                                    data-pswp-width={image.width}
                                    data-pswp-height={image.height}
                                    target="_blank"
                                    rel="noreferrer"
                                    data-cropped="true"
                                    aria-label="Ingrandisci la foto"
                                >
                                    <SmartImage url={image.url} conversions={image.conversions} data={image.data} />
                                    <span className="pswp-caption-content">
                                        {imageTitle.trim() !== "" && <strong>{imageTitle}</strong>}
                                        {imageCopyright.trim() !== "" && (
                                            <small className="d-block mt-1">{imageCopyright}</small>
                                        )}
                                    </span>
                                </a>
                                {imageTitle.trim() !== "" && (
                                    <div className={`${styles.imgDescriptionWrapper}`}>
                                        <label itemProp="name" className="fst-italic">
                                            {imageTitle}
                                            {imageCopyright.trim() !== "" && ` - ${imageCopyright}`}
                                        </label>
                                    </div>
                                )}
                                <small className="d-block mt-1 fst-italic">{imageCopyright}</small>
                            </div>
                        );
                    })}
                </div>
                <div
                    className={`swiper-button-prev swiperButtonPrev ${coverImages.length === 1 && "d-none"}`}
                    dangerouslySetInnerHTML={{
                        __html: SVG.leftSwiperArrow,
                    }}
                ></div>
                <div
                    className={`swiper-button-next swiperButtonNext ${coverImages.length === 1 && "d-none"}`}
                    dangerouslySetInnerHTML={{
                        __html: SVG.rightSwiperArrow,
                    }}
                ></div>
            </div>
        );
    };

    return (
        <>
            <section
                className={`${styles.wrapper}`}
                data-gtrack={true}
                data-gtrack-id={magazineNews.id}
                data-gtrack-type={"magazine_news"}
                data-gtrack-action={["VIEW"]}
                data-gtrack-link={magazineNews?.url?.[locale]?.url}
                data-gtrack-threshold={0.1}
            >
                {/** Share buttons */}
                <ShareList links={shareLinks} />

                {/** Article body */}
                <article className={styles.article}>
                    <header>
                        {/* Data + Ora + Modificato in data */}
                        <span className={`d-block mb-2 text-uppercase font-sans ${styles.pretitle}`}>
                            {displayDate}
                            <span className={styles.pretitleDateUpdated}>&nbsp;{displayTime}</span>
                        </span>

                        {/* Pre-title */}
                        <span className={`d-block mb-2 text-uppercase font-sans ${styles.pretitle}`}>
                            {magazineNews.pre_title[locale]}
                        </span>

                        {/* Titolo */}
                        <h1 className={styles.title}>
                            {magazineNews.title[locale] ? magazineNews.title[locale] : magazineNews.name}
                        </h1>

                        {/* Autore */}
                        <span className={`my-2 d-block fst-italic ${styles.author}`}>
                            di {magazineNews.author_nickname}
                        </span>

                        {/* Sommario */}
                        <div
                            className={`h5 fst-italic ${styles.summary}`}
                            dangerouslySetInnerHTML={{
                                __html: magazineNews.summary[locale],
                            }}
                        />

                        {/* Cover Image */}
                        {!paywalled &&
                            !isAudioNews &&
                            !isVideoNews &&
                            !hasMediaAsCover &&
                            magazineNews.cover_image &&
                            magazineNews.cover_image.length > 0 && (
                                <CoverImageSwiper coverImages={magazineNews.cover_image} />
                            )}

                        {!paywalled && hasMediaAsCover && FileUtils.isPDF(coverMedia.filename) && (
                            <div>
                                <PDFViewer file={`/storage/${coverMedia.filename}`} />
                            </div>
                        )}

                        {!paywalled && hasMediaAsCover && FileUtils.isAudio(coverMedia.filename) && (
                            <div className={`mt-4 mb-3 ${styles.audioWrapper}`}>
                                <AudioPlayer file={`/storage/${coverMedia.filename}`} />
                            </div>
                        )}
                    </header>

                    {paywalled ? <SubscriptionPaywall /> : ArticleContents()}
                </article>
            </section>
            <hr className="separatore"></hr>
        </>
    );
}

export default NewsArticle;
