import React, { useState, useEffect } from 'react';
import styles from './SubscriptionList.module.scss';
import { usePage } from '@inertiajs/react';

import PrivateAreaUtils from '../../../Utils/PrivateAreaUtils';
import SubscriptionCard from '../SubscriptionCard/Index';

const CACHE_DURATION = 60 * 60 * 1000; // Durata della cache in millisecondi (1 ora)

const SubscriptionList = () => {

    const {  userLogged } = usePage().props;

    const userHasSubscription = Boolean(userLogged?.private_package_active);

    {/*
      ** Se l'utente ha un abbonamento, non può abbonarsi di nuovo.
      ** Di conseguenza interrompo il render del componente e ritorno una stringa
      ** che avvisa che l'utente è già abbonato.
    */}
    if (userHasSubscription) {
        return (
            <strong className='text-success text-center mt-3 d-block'>
                Hai già sottoscritto un abbonamento.
            </strong>
        )
    }
    
    const [subscriptionGroups, setSubscriptionGroups] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadSubscriptions = async () => {
            setIsLoading(true);
            // Controlla prima nel localStorage
            const cache = localStorage.getItem('subscriptionCache');
            const now = new Date().getTime();

            let subscriptions;
            if (cache) {
                const { lastFetch, data } = JSON.parse(cache);
                if (now - lastFetch < CACHE_DURATION && data.length > 0) {
                    subscriptions = data; // Usa i dati della cache se sono ancora validi
                }
            }

            if (!subscriptions) {
                // Carica i dati da API e salva nel localStorage con timestamp
                subscriptions = await PrivateAreaUtils.subscription.getAll(true);
                localStorage.setItem('subscriptionCache', JSON.stringify({ data: subscriptions, lastFetch: now }));
            }

            if (subscriptions.length > 0) {
                const groupedSubscriptions = groupSubscriptionsByValidity(subscriptions);
                setSubscriptionGroups(groupedSubscriptions);
            }
            setIsLoading(false);
        };

        loadSubscriptions();
    }, []);

    const groupSubscriptionsByValidity = (subscriptions) => {
        return subscriptions.reduce((groups, subscription) => {
            const validity = subscription.day_of_validity || 'default';
            if (!groups[validity]) {
                groups[validity] = [];
            }
            groups[validity].push(subscription);
            return groups;
        }, {});
    };

    const getValidityLabel = (validity) => {
        switch (validity) {
            case 30:
                return 'Mensile';
            case 365:
                return 'Annuale';
            default:
                return 'Default';
        }
    };

    const subscriptionList = isLoading ? (
        <strong className='text-center mt-3 d-block'>
            Caricamento degli abbonamenti disponibili...
        </strong>
    ) : (
        <>
            <ul className="nav nav-pills my-4 my-sm-5 font-sans" id="pills-tab" role="tablist">
                {Object.keys(subscriptionGroups).map((validity, index) => (
                    <li className="nav-item" role="presentation" key={validity}>
                        <button
                            className={`nav-link text-uppercase ${index === 0 ? "active" : ""}`}
                            id={`subscription-group-${validity}-tab`}
                            data-bs-toggle="pill"
                            data-bs-target={`#subscription-group-${validity}`}
                            type="button"
                            role="tab"
                            aria-controls={`subscription-group-${validity}`}
                            aria-selected={index === 0 ? "true" : "false"}
                        >
                            {getValidityLabel(Number(validity))}
                        </button>
                    </li>
                ))}
            </ul>

            <div className="tab-content" id="pills-tabContent">
                {Object.entries(subscriptionGroups).map(([validity, subscriptions], index) => (
                    <div
                        className={`tab-pane fade ${index === 0 ? "show active" : ""}`}
                        id={`subscription-group-${validity}`}
                        role="tabpanel"
                        aria-labelledby={`subscription-group-${validity}-tab`}
                        tabIndex="0"
                        key={validity}
                    >
                        <ul className={`${styles.grid} mt-4`}>
                            {subscriptions.map(subscription => {
                                return (
                                    <li key={`SubscriptionCard-${subscription.id}`}>
                                        <SubscriptionCard {...subscription} />
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                ))}
            </div>
        </>
    )

    return subscriptionList;
};

export default SubscriptionList;
